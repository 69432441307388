/* You can add global styles to this file, and also import other style files */
body{
    background-image: url(assets/background/seamless-bg.jpg);
}

.cursor-pointer{cursor: pointer}

.card-img{
  margin-top: 30px;
  width: 64px;
  height: 64px
}

.header-nalenha{
    background-color: #ffd800 !important;
}

.loading-image {
  background-color: transparent !important;
  position: absolute;
  margin-left: -50px;
  margin-top: -30px;
  left: 50%;
  top: 50%;
  z-index: 9999;
}
.loading-image:before{
    width: 200px;
    height: 200px;
    background-color: red;

}


.form-control.ng-select {
  border: none;
  padding: 0;
}

.ng-dropdown-panel{
    display: inline-table;
}

.statusEnum.NaoDefinido {
  background-color: #000;
  color: #fff;
}
.statusEnum.EmAndamento {
  background-color: #00ff90;
  color: #000;
}
.statusEnum.Forno {
  background-color: #ff6a00;
  color: #000;
}
.statusEnum.SaiuParaEntrega {
  background-color: #b200ff;
  color: #fff;
}
.statusEnum.Entregue {
  background-color: #00ff21;
  color: #000;
}
.statusEnum.Cancelado {
  background-color: #ff006e;
  color: #fff;
}
.statusEnum.Recusado {
    background-color: #808080;
    color: #fff;
}
.statusEnum.Retirar {
  background-color: #00ff21;
  color: #000;
}

